import axios from "axios";

const api = axios.create({
  baseURL: "https://ticket-api-bbi-2024.k8s-dev.yazo.com.br/user",
});


api.interceptors.response.use(
  (response) => {
    return response;
  },
);

export default api;
